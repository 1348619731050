footer {
  width: 100%;
  height: 100px;
  margin-top: 50px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-link-wrapper {
  width: 120px;
  height: 100px;
  padding: 18px 18px 0px 18px;
  display: flex;
  justify-content: space-between;
  border-radius: 40px 40px 0px 0px;
  box-shadow: 0px 0px 10px -3px $shadow-gray;
}

.social-icon {
  width: 48px;
  height: 48px;
  display: inline-block;
  cursor: pointer;
}

.filter-pink {
  filter: invert(75%) sepia(38%) saturate(5524%) hue-rotate(287deg)
    brightness(101%) contrast(99%);
}
