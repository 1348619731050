@import "consts";

.form-background {
  background: url($form-bg-image) center center no-repeat;
  background-size: cover;
  width: 300px;
  height: 450px;
  margin-top: 50px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-radius: 3vh;
  box-shadow: 0px 0px 10px -3px $shadow-gray;
}

.contact-us {
  background: $cream;
  padding: 50px 100px;
  border: 2px solid $black;
  box-shadow: 15px 15px 1px $pink-dark, 15px 15px 1px 2px $black;
}

input {
  display: block;
  width: 100%;
  font-size: $font-size;
  line-height: $font-size * 2;
  font-family: $font-face;
  margin-bottom: $font-size * 2;
  border: none;
  border-bottom: 5px solid $black;
  background: $cream;
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  color: $black;
}

input:focus {
  border-bottom: 5px solid $pink-dark;
}

textarea {
  display: block;
  width: 100%;
  height: 100px;
  font-size: $font-size;
  line-height: $font-size * 2;
  font-family: $font-face;
  margin-bottom: $font-size * 2;
  border: none;
  border-bottom: 5px solid $black;
  background: $cream;
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  color: $black;
}

textarea:focus {
  border-bottom: 5px solid $pink-dark;
}

button {
  display: block;
  margin: 0 auto;
  line-height: $font-size * 2;
  padding: 0 20px;
  background: $pink-dark;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid $black;
  box-shadow: 3px 3px 1px 1px $pink-medium, 3px 3px 1px 2px $black;
  cursor: pointer;
}

hover {
  background: $black;
  color: white;
  border: 1px solid $black;
}

::selection {
  background: $pink-light;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border-bottom: 5px solid $pink-medium;
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $cream inset;
  transition: background-color 5000s ease-in-out 0s;
}
