@import url("https://fonts.googleapis.com/css?family=Fjalla+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chewy|Grand+Hotel|Oswald&display=swap");
@import url("https://fonts.googleapis.com/css?family=oswald:400,300,500");
.food-truck-image {
  width: 100%;
  max-width: 600px;
  height: "auto";
  border-radius: 40px;
  margin-bottom: 30px;
}

.about-text-wrapper {
  width: 90%;
  max-width: 600px;
  height: "auto";
  text-align: start;
  padding: 20px;
}

.about-text {
  margin-bottom: 10px;
}

.form-background {
  background: url("sprinkles.svg") center center no-repeat;
  background-size: cover;
  width: 300px;
  height: 450px;
  margin-top: 50px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-radius: 3vh;
  box-shadow: 0px 0px 10px -3px #858585;
}

.contact-us {
  background: #f8f5e5;
  padding: 50px 100px;
  border: 2px solid #000000;
  box-shadow: 15px 15px 1px #d253a5, 15px 15px 1px 2px #000000;
}

input {
  display: block;
  width: 100%;
  font-size: 14pt;
  line-height: 28pt;
  font-family: "Fjalla One";
  margin-bottom: 28pt;
  border: none;
  border-bottom: 5px solid #000000;
  background: #f8f5e5;
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  color: #000000;
}

input:focus {
  border-bottom: 5px solid #d253a5;
}

textarea {
  display: block;
  width: 100%;
  height: 100px;
  font-size: 14pt;
  line-height: 28pt;
  font-family: "Fjalla One";
  margin-bottom: 28pt;
  border: none;
  border-bottom: 5px solid #000000;
  background: #f8f5e5;
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  color: #000000;
}

textarea:focus {
  border-bottom: 5px solid #d253a5;
}

button {
  display: block;
  margin: 0 auto;
  line-height: 28pt;
  padding: 0 20px;
  background: #d253a5;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid #000000;
  box-shadow: 3px 3px 1px 1px #ff8ad6, 3px 3px 1px 2px #000000;
  cursor: pointer;
}

hover {
  background: #000000;
  color: white;
  border: 1px solid #000000;
}

::selection {
  background: #ffc8ff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border-bottom: 5px solid #ff8ad6;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px #f8f5e5 inset;
  transition: background-color 5000s ease-in-out 0s;
}

footer {
  width: 100%;
  height: 100px;
  margin-top: 50px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-link-wrapper {
  width: 120px;
  height: 100px;
  padding: 18px 18px 0px 18px;
  display: flex;
  justify-content: space-between;
  border-radius: 40px 40px 0px 0px;
  box-shadow: 0px 0px 10px -3px #858585;
}

.social-icon {
  width: 48px;
  height: 48px;
  display: inline-block;
  cursor: pointer;
}

.filter-pink {
  filter: invert(75%) sepia(38%) saturate(5524%) hue-rotate(287deg) brightness(101%) contrast(99%);
}

header {
  z-index: 1;
  width: 100vw;
  height: 200px;
  padding-top: 15px;
  padding-bottom: 35px;
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.header-logo {
  height: 200px;
}

.home-link {
  height: 200px;
}

.landing-logo {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 80vmin;
  pointer-events: none;
  border-radius: 400px;
  -webkit-box-shadow: 0px 0px 50px -3px rgb(255, 238, 108);
  -moz-box-shadow: 0px 0px 50px -3px rgb(255, 238, 108);
  box-shadow: 0px 0px 50px -3px rgb(255, 238, 108);
}

.menu-image {
  position: relative;
  top: -250px;
}

.menu-item-spacing {
  margin-top: 15px;
}

nav {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 300px;
  background: #ff8ad6;
  color: #000000;
  -webkit-clip-path: circle(24px at 30px 24px);
  clip-path: circle(24px at 32px 24px);
  -webkit-transition: -webkit-clip-path 0.5625s, clip-path 0.375s;
  transition: -webkit-clip-path 0.5625s, clip-path 0.375s;
  border-radius: 0px 10px 50px 10px;
  /* font-family: "Oswald", serif; */
}

nav:hover {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 0.75s;
  transition-duration: 0.75s;
  -webkit-clip-path: circle(390px at 225px 24px);
  clip-path: circle(390px at 150px 24px);
}

.link {
  display: block;
  line-height: 50px;
  padding: 0 20px;
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.nav-link:hover {
  background: #f8f5e5;
}

.bottom-a {
  border-radius: 0px 0px 50px 10px;
}

.nav-link:active {
  background: #f8f5e5;
}

.navicon {
  padding: 23px 20px;
  cursor: pointer;
  -webkit-transform-origin: 32px 24px;
  -ms-transform-origin: 32px 24px;
  transform-origin: 32px 24px;
}

.navicon div {
  position: relative;
  width: 20px;
  height: 2px;
  background: #000000;
}

.navicon div:before,
.navicon div:after {
  display: block;
  content: "";
  width: 20px;
  height: 2px;
  background: #000000;
  position: absolute;
}

.navicon div:before {
  top: -7px;
}

.navicon div:after {
  top: 7px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landing {
  text-align: center;
  background-color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .mini {
    font-size: 20px;
  }
  .donuts {
    font-size: 90px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

