header {
  z-index: 1;
  width: 100vw;
  height: 200px;
  padding-top: 15px;
  padding-bottom: 35px;
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.header-logo {
  height: 200px;
}

.home-link {
  height: 200px;
}
