nav {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 300px;
  background: $pink-medium;
  color: $black;
  -webkit-clip-path: circle(24px at 30px 24px);
  clip-path: circle(24px at 32px 24px);
  -webkit-transition: -webkit-clip-path 0.5625s, clip-path 0.375s;
  transition: -webkit-clip-path 0.5625s, clip-path 0.375s;
  border-radius: 0px 10px 50px 10px;
  /* font-family: "Oswald", serif; */
}

nav:hover {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 0.75s;
  transition-duration: 0.75s;
  -webkit-clip-path: circle(390px at 225px 24px);
  clip-path: circle(390px at 150px 24px);
}

.link {
  display: block;
  line-height: 50px;
  padding: 0 20px;
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.nav-link:hover {
  background: $cream;
}

.bottom-a {
  border-radius: 0px 0px 50px 10px;
}

.nav-link:active {
  background: $cream;
}

.navicon {
  padding: 23px 20px;
  cursor: pointer;
  -webkit-transform-origin: 32px 24px;
  -ms-transform-origin: 32px 24px;
  transform-origin: 32px 24px;
}

.navicon div {
  position: relative;
  width: 20px;
  height: 2px;
  background: $black;
}

.navicon div:before,
.navicon div:after {
  display: block;
  content: "";
  width: 20px;
  height: 2px;
  background: $black;
  position: absolute;
}

.navicon div:before {
  top: -7px;
}

.navicon div:after {
  top: 7px;
}
