.landing-logo {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 80vmin;
  pointer-events: none;
  border-radius: 400px;
  -webkit-box-shadow: 0px 0px 50px -3px $yellow;
  -moz-box-shadow: 0px 0px 50px -3px $yellow;
  box-shadow: 0px 0px 50px -3px $yellow;
}
