.food-truck-image {
  width: 100%;
  max-width: 600px;
  height: "auto";
  border-radius: 40px;
  margin-bottom: 30px;
}

.about-text-wrapper {
  width: 90%;
  max-width: 600px;
  height: "auto";
  text-align: start;
  padding: 20px;
}

.about-text {
  margin-bottom: 10px;
}
