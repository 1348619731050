@use "assets/consts";

@import url("https://fonts.googleapis.com/css?family=Fjalla+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chewy|Grand+Hotel|Oswald&display=swap");
@import url("https://fonts.googleapis.com/css?family=oswald:400,300,500");

@import "assets/about";
@import "assets/calendar";
@import "assets/contact-form";
@import "assets/contact";
@import "assets/footer";
@import "assets/header";
@import "assets/landing";
@import "assets/menu";
@import "assets/navigation";
@import "assets/page-wrapper";

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-wrapper {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
}

.landing {
  text-align: center;
  background-color: $white;
}

@media only screen and (max-width: 600px) {
  .mini {
    font-size: 20px;
  }

  .donuts {
    font-size: 90px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
