$yellow: rgb(255, 238, 108);
$pink-dark: #d253a5;
$pink-medium: #ff8ad6;
$pink-light: #ffc8ff;
$cream: #f8f5e5;
$white: #ffffff;
$shadow-gray: #858585;
$black: #000000;

$font-size: 14pt;
$font-face: "Fjalla One";

$form-bg-image: "sprinkles.svg";
